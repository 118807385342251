import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CaptchaService {

  u02751xcyu = 'stIfPU1xd54VSUBhpZG1eQ8D5tIeZAgo9AoAJffn'

  encryptData(data: string) {

    const localTimeDiff=sessionStorage.getItem("timeGap") ?? "0"
    const currentDate=new Date().setMilliseconds(Number.parseInt(localTimeDiff));
    data =new Date(currentDate).toISOString();

    const keyBytes = new TextEncoder().encode(this.u02751xcyu.slice(0, 32));
    const keyPromise = window.crypto.subtle.importKey(
      "raw",
      keyBytes.slice(0, 32),
      { name: "AES-GCM",length: 256},
      false,
      ["encrypt"]
    );

    return keyPromise.then((cryptoKey: any) => {
      const iv = window.crypto.getRandomValues(new Uint8Array(12)); // IV should be 12 bytes

      const dataBytes = new TextEncoder().encode(data); // Convert data to bytes

      return window.crypto.subtle.encrypt(
        {
          name: "AES-GCM",
          iv: iv,
        },
        cryptoKey,
        dataBytes
      ).then((encrypted: ArrayBuffer) => {
        const encryptedArray = new Uint8Array(encrypted);

        const combined = new Uint8Array(iv.length + encryptedArray.length);
        combined.set(iv); // Set IV at the start
        combined.set(encryptedArray, iv.length); // Set encrypted data after IV

        return btoa(String.fromCharCode(...combined)); // Encode to Base64 for safe transmission
      })
    });
  }
}
